<template>
    <div class="wish">
        <div class="content"> <!-- 顶部 -->
            <!-- <Header :titleName="'微心愿'" :return="false" :borderShow="true" /> -->

            <div style="
          font-size: .6rem;
          text-align: center;
          margin-top: 40vh;
                  ">稍后奉上</div>
        </div>
        <Footer />
    </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";

let data = reactive({
    
});
</script>

<style lang='less' scoped>
.wish {
    display: flex;
    flex-flow: column;
    height: 100%;

    .content {
        flex: 1;
        overflow-y: auto;
        background-color: #fff;

        

    }
}
</style>